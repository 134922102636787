import * as React from 'react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

import { cn } from '@/lib/utils';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isPasswordField?: boolean;
  isVisibleText?: boolean;
  setIsVisibleText?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      isPasswordField,
      isVisibleText,
      setIsVisibleText,
      ...props
    },
    ref
  ) => {
    return (
      <div className="relative">
        <input
          type={type}
          className={cn(
            'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
            className
          )}
          ref={ref}
          {...props}
        />
        {isPasswordField && (
          <div className="absolute right-[14px] top-[9px]">
            {!isVisibleText ? (
              <FaRegEye
                onClick={() => setIsVisibleText?.(!isVisibleText)}
                className="cursor-pointer"
                size={20}
              />
            ) : (
              <FaRegEyeSlash
                onClick={() => setIsVisibleText?.(!isVisibleText)}
                className="cursor-pointer"
                size={20}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
